@import url('https://fonts.googleapis.com/css2?family=Sen:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: #021d44;
}
